import './about.css'



export default function About() {
    return (
        <>
        
        <header className="aboutMasthead" style= {{backgroundImage: `url(${process.env.PUBLIC_URL + "/images/universe.jpg"})`}}>
        <div className="container position-relative px-4 px-lg-5">
             <div className="row gx-4 gx-lg-5 justify-content-center">
                    <div className="col-md-10 col-lg-8 col-xl-7">
                        <div className="aboutTitles">
                            <h1 className="aboutTitle">About Me</h1>
                            <span className="aboutSubheading">Amy who?</span>
                        </div>
                    </div>
               
        </div>
        </div>
        
        </header>
        <div className="container">
            <div className="row">
                <div className="col-lg-8 col-md-10 mx-auto">
                <p className="aboutContent">With the uncertainties of the 20-21 school year looming, I left a position as an assistant principal to
                support the needs of my two children. While out of the work force, I pursued a dream that I had always harbored. With the support of courses on
                Pluralsight and Udemy, I taught myself how to program. I have found that I love coding. I enjoy the
                creativity, the analytical problem solving, and the limitless possibilities. </p>
                <p className="aboutContent">I am currently working in a part-time position teaching elementary students to code. 
                I am also looking for opportunties to contribute to projects that combine my background 
                in education with my growing software development skills.</p>
                
                </div>
            </div>
        </div>
        </>
        
        
     
    )
}
