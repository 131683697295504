import React from "react";
import "./footer.css"

const today = new Date();

export default function  Footer(){
    return (
    <footer className = "footer-pin mt-auto py-3">
        <div className="container">
        <p className="footer-paragraph">Created by Amy Thompson</p>
        <p className="footer-paragraph">Copyright © {today.getFullYear()}</p>
        </div>
    </footer>
    )
}

