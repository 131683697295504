import { useState, useEffect } from 'react'
import './write.css'
import axios from 'axios'

export default function Write() {
  const [title, setTitle] = useState('')
  const [desc, setDesc] = useState('')
  const [file, setFile] = useState(null)
  const [isProcessing, setIsProcessing] = useState(false)

  const getIsLoggedIn = async () => {
    try {
      const response = await axios.get('/api/isLoggedIn')
      return response.data === 'true' || response.data === true
    } catch (err) {
      return false
    }
  }

  useEffect(() => {
    getIsLoggedIn().then(function(res) {
      if (!res) {
        window.location.href = '/'
      }
    })
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (isProcessing) {
      return
    }

    setIsProcessing(true)

    try {
      const data = new FormData()
      data.append('title', title)
      data.append('desc', desc)

      if (file) {
        const imageFilename = Date.now() + file.name
        data.append('imageFilename', imageFilename)
        data.append('file', file)
      }

      const res = await axios({
        method: 'POST',
        url: '/api/posts',
        data: data,
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      window.location.replace('/post/' + res.data._id)
    } catch (err) {
      console.error(err)
    } finally {
      setIsProcessing(false)
    }
  }

  return (
    <>
      <div className="container">
        <div className="row justify-content-lg-center">
          <div className="col-md-12">
            {file && (
              <img
                className="writeImg"
                src={URL.createObjectURL(file)}
                alt=""
              />
            )}
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row justify-content-md-center">
          <form className="writeForm" onSubmit={handleSubmit}>
            <div className="row justify-content-center writeFormGroup">
              <div className="col-md-10">
                <div className="d-flex align-items-center writeFormGroup">
                  <div className="d-inline-block">
                    <label htmlFor="fileInput">
                      <i className="writeIcon fas fa-plus"></i>
                    </label>
                    <input
                      type="file"
                      id="fileInput"
                      style={{ display: 'none' }}
                      onChange={(e) => setFile(e.target.files[0])}
                    />
                  </div>
                  <div className="d-inline-block">
                    <input
                      type="text"
                      placeholder="Title"
                      className="writeInput"
                      autoFocus={true}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </div>
                </div>
                <div className="writeFormGroup">
                  <textarea
                    className="writeInput writeText"
                    type="text"
                    placeholder="Tell hey your story..."
                    onChange={(e) => setDesc(e.target.value)}
                  ></textarea>
                </div>
              </div>
              <div className="col-md-2">
                <div className="row">
                  <button className="writeSubmit" type="submit">
                    Publish
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}
