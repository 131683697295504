import Post from "../post/Post";
import "./posts.css";

export default function Posts({ posts }) {
  return (
    <div className="posts container">
      <div className="row align-items-start">
        {posts.map((p, index) => (
        
          <div className="col-md-6 d-flex p-2" key={index}>
            <Post post={p} />
          </div>
        ))}
      </div>
    </div>
  );
}
