import axios from "axios"
import {useEffect, useState} from "react";
import {useLocation} from "react-router-dom"
import "./singlePost.css";

export default function SinglePost() {
    const location = useLocation()
    const path = location.pathname.split("/")[2]
    const [post, setPost] = useState({})
    const [imgSrc, setImgSrc] = useState('')
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const [title, setTitle] = useState("")
    const [desc, setDesc] = useState("")
    const [updateMode, setUpdateMode] = useState(false)

    useEffect(() => {
        const getPost = async () => {
            const res = await axios.get('/api/posts/' + path)
            setPost(res.data)
            setTitle(res.data.title)
            setDesc(res.data.desc)
            if (res.data.imgSrc) {
                setImgSrc(res.data.imgSrc)
            }
        };
        getPost()
    }, [path]);

    useEffect(() => {
        const getIsLoggedIn = async () => {
            try {
                const response = await axios.get('/api/isLoggedIn')
                setIsLoggedIn(response.data)
            } catch (err) {
                console.log('error: ', err)
            }
        }
        getIsLoggedIn()
    }, [])

    const handleDelete = async (e) => {
        e.preventDefault()
        try {
            await axios.delete(`/api/posts/${post._id}`)
            window.location.replace("/");
        } catch (err) {
            console.error(err)
        }
    }

    const handleUpdate = async () => {
        try {
            await axios.put(`/api/posts/${post._id}`,
                {
                    title: title,
                    desc: desc,
                }
            );
            window.location.reload();
        } catch (err) {
            console.error(err)
        }
    }


    return (
        <div className="singlePost">
            <div className="singlePostWrapper">
                {imgSrc &&
                <img
                    src={imgSrc}
                    alt=""
                    className="singlePostImg"
                />
                }
                {updateMode ? (<input type="text" value={title} className="singlePostTitleInput" onChange={(e) => setTitle(e.target.value)}/>
                ) : (

                        <h1 className="singlePostTitle">
                            {post.title}
                            {isLoggedIn && (
                                <div className="singlePostEdit">
                                    <i className="editIcon singlePostIcon fas fa-edit"
                                       onClick={() => setUpdateMode(true)}></i>
                                    <i className="deleteIcon singlePostIcon fas fa-trash" onClick={(e) => { if (window.confirm('Are you sure?')) handleDelete(e)}}></i>
                                </div>
                            )}
                        </h1>
                    )}


                    < div className="singlePostInfo">
                    <span className="singlePostAuthor">
                    Author: <b>Amy T</b>
                    </span>
                    <span className="singlePostDate">{new Date(post.createdAt).toDateString()}</span>
                    </div>
                {updateMode ? (
                    <textarea
                        className="singlePostDescInput"
                        value={desc}
                        onChange={(e) => setDesc(e.target.value)}
                    />
                ) : (
                    <p className="singlePostContent">{post.desc}</p>
                )}
                {updateMode &&<button className="singlePostButton" onClick={handleUpdate}>Update Post</button>}

                    </div>
                    </div>
                    );
                }
