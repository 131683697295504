/* eslint-disable jsx-a11y/anchor-is-valid */
import './navbar.css'

import {Link} from 'react-router-dom'
import {useEffect, useState} from 'react'
import axios from 'axios'

export default function TopBar() {
    const [isLoggedIn, setIsLoggedIn] = useState(false)

    useEffect(() => {
        const getIsLoggedIn = async () => {
            try {
                const response = await axios.get('/api/isLoggedIn')
                setIsLoggedIn(response.data)
            } catch (err) {
                console.log('error: ', err)
            }
        }
        getIsLoggedIn()
    }, [])

    const logout = async (e) => {
        e.preventDefault()
        await axios.post('/api/logout')
        window.location.href = '/'
    }
    return (
        <nav className="nav-top navbar navbar-expand-lg navbar-light bg-light">
            <div className="container">
                <Link className="navbar-brand" to="/">Amy Thompson</Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <Link
                                to="/"
                                style={{ textDecoration: 'none', color: 'inherit' }}
                            >
                                HOME
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link
                                to="about"
                                style={{ textDecoration: 'none', color: 'inherit' }}
                            >
                                ABOUT
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link
                                to="contact"
                                style={{ textDecoration: 'none', color: 'inherit' }}
                            >
                                CONTACT
                            </Link>
                        </li>

                        {isLoggedIn && (
                            <li className="nav-item">
                                <Link
                                    to="write"
                                    style={{ textDecoration: 'none', color: 'inherit' }}
                                >
                                    WRITE
                                </Link>
                            </li>
                        )}
                        {isLoggedIn &&
                            <li className="link nav-item">
                                <a href="#" onClick={logout}>
                                    LOGOUT
                                </a>
                            </li>
                        }
                    </ul>

                </div>
            </div>
        </nav>



    )

}
