import "./post.css";
import { Link } from "react-router-dom"

export default function Post({post}) {
  return (
    <div className="post">
    
      {post.imgSrc && 
      <div>
      <img
        className="postImage img-fluid vw-100"
        src={post.imgSrc}
        alt=""
      />
      </div>}
      <div className="postInfo">
        
        <Link to={`/post/${post._id}`} className="link">
        <span className="postTitle">{post.title}</span>
        </Link>
      
        <span className="postDate">{new Date(post.createdAt).toDateString()}</span>
      </div>
      <p className="postDesc">
        {post.desc}
      </p>
    </div>
   
  );
}
