import React from "react";
import ReactDOM from "react-dom";
import axios from 'axios'

import App from "./App";

axios.defaults.headers.common['X-Application-Id'] = 'react_blog'

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
