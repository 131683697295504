import "./header.css"


export default function Header() {
    return (
        <header className="masthead" style= {{backgroundImage: `url(${process.env.PUBLIC_URL + "/images/mountains.jpg"})`}}>
        <div className="container position-relative px-4 px-lg-5">
             <div className="row gx-4 gx-lg-5 justify-content-center">
                    <div className="col-md-10 col-lg-8 col-xl-7">
                        <div className="headerTitles">
                            <h1 className="headerTitle">Always Learning</h1>
                            <span className="headerSubheading"></span>
                        </div>
                    </div>
               
        </div>
        </div>
        
        </header>
    )
}
