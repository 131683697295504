import { useEffect, useState } from "react";
import Header from "../../components/header/Header";
import Posts from "../../components/posts/Posts";
import Sidebar from "../../components/sidebar/Sidebar";
import "./home.css";
import axios from "axios";

export default function Home() {
  const [posts, setPosts] = useState([]);

  useEffect(()=> {
    const fetchPosts = async () => {
      const res = await axios.get('/api/posts')
      setPosts(res.data)
    }
    fetchPosts()
  }, [])
  return (
    <>
      <Header />
      <div className="container">
        <div className="row">
          <div className="col-md-9">
            <Posts posts={posts} />
          </div>
          <div className="col-md-3">
            <Sidebar />
          </div>
        </div>
      </div>
    </>
  );
}
