import './settings.css'
import Sidebar from "../../components/sidebar/Sidebar"


export default function Settings() {
    return (
        <>
      <div className="container">
        <div className="settings row">
          <div className="col-md-9">
            <div className="settingsTitle">
                <span className="settingsUpdateTitle">Update Your Account</span>
                <span className="settingsDeleteTitle">Delete Your Account</span>
            </div>
            <form className="settingsForm">
            <label>Profile Picture</label>
            <div className="settingsPP">
                <img src="./amypic.png" alt="" />
                <label htmlFor="fileInput">
                <i className="settingsPPIcon far fa-user-circle"></i>
                </label>
                <input type="file" id="fileInput" style={{display:"none"}}/>
            </div>
            <label>Username</label>
            <input type="text" plaeholder="username" />
            <label>Email</label>
            <input type="text" plaeholder="email" />
            <label>Password</label>
            <input type="text" plaeholder="enter password" />
            <button className="settingsSubmit">Update</button>
            </form>
          </div>
          <div className="col-md-3">
            <Sidebar />
          </div>
        </div>
      </div>
    </>
    )
}
