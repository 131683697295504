import { useRef, useState } from 'react'
import axios from 'axios'
import './login.css'

export default function Login() {
  const username = useRef(null)
  const password = useRef(null)
  const [isProcessing, setIsProcessing] = useState(false)
  const [isError, setIsError] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (isProcessing) {
      return
    }
    try {
      setIsError(false)
      setIsProcessing(true)
      // const testResult = await axios.get('/api/testendpoint')
      // console.log('hello?: ', testResult)
      const data = {
        username: username.current.value,
        password: password.current.value,
      }
      console.log('about to post data: ', data)
      const result = await axios.post('/api/login', data)
      console.log(result)
      window.location.href = '/'
    } catch (err) {
      console.log(err)
      setIsError(true)
    } finally {
      setIsProcessing(false)
    }
  }

  return (
    <div className="login">
      <span className="loginTitle">Login</span>
      <form className="loginForm">
        <label>Username</label>
        <input
          id="username"
          type="text"
          className="loginInput"
          placeholder="Enter your username..."
          ref={username}
        />
        <label>Password</label>
        <input
          id="password"
          type="password"
          className="loginInput"
          placeholder="Enter your password..."
          ref={password}
        />
        <button className="loginButton" type="submit" onClick={handleSubmit}>
          Login
        </button>
        {isError && (
          <p style={{ color: 'red' }}>Incorrect username or password</p>
        )}
      </form>
    </div>
  )
}
