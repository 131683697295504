import axios from "axios"
import { useEffect } from "react"
import Navbar from "../../components/navbar/Navbar";

export default function Sandbox() {
  useEffect(() => {
    const getStuff = async () => {
      const response = await axios.get('/api/sandbox')
      console.log('response: ' + response.data)
    }
    getStuff()
  }, [])
  return (<Navbar />)
}
