
import React from "react";
import Home from "./pages/home/Home";
import Single from "./pages/single/Single";
import Write from "./pages/write/Write";
import Settings from "./pages/settings/Settings";
import Login from "./pages/login/Login";
import About from "./pages/about/About";
import Sandbox from './pages/sandbox/Sandbox'
import Navbar from "./components/navbar/Navbar";
import Contact from "./pages/contact/Contact";

import { BrowserRouter as Router, Routes, Route} from "react-router-dom";
import Footer from "./components/footer/Footer";

function App() {
  return (
    <Router>
      <Navbar />
        <div className="content-container">

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="about" element={<About />} />
        <Route path="contact" element={<Contact />} />
        <Route path="login" element={<Login />} />
        <Route path="write" element={<Write />} />
        <Route path="settings" element={<Settings />} />
        <Route path="post/:postId" element={<Single />} />
        <Route path="sandbox" element={<Sandbox />} />
      </Routes>
        </div>
        <Footer />
    </Router>
  );
}

export default App;
