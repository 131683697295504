import axios from 'axios'
import { useRef, useState } from 'react'
import './contact.css'
import classNames from 'classnames'

export default function Contact() {
  const [isLoading, setIsLoading] = useState(false)
  const [isSent, setIsSent] = useState(false)
  const [isNameValid, setIsNameValid] = useState(true)
  const [isEmailValid, setIsEmailValid] = useState(true)
  const [isMessageValid, setIsMessageValid] = useState(true)
  const [errors, setErrors] = useState([])
  const name = useRef(null)
  const email = useRef(null)
  const message = useRef(null)

  const sendMessage = async (e) => {
    e.preventDefault()
    if (isLoading) {
      return
    }

    try {
      await axios.post('/api/contact', {
        name: name.current.value,
        email: email.current.value,
        message: message.current.value
      })
      setIsSent(true)
      setTimeout(() => {
        window.location.href = '/'
      }, 2000)
    } catch (err) {
      const errorEntries = err?.response?.data?.errors
      console.log(errorEntries)
      if (!errorEntries) {
        return
      }
      let newErrors = []
      if (errorEntries.filter(e => e.param === 'name').length > 0) {
        newErrors.push('Please provide your name')
        setIsNameValid(false)
      }
      if (errorEntries.filter(e => e.param === 'email').length > 0) {
        newErrors.push('Please provide a valid email')
        setIsEmailValid(false)
      }
      if (errorEntries.filter(e => e.param === 'message').length > 0) {
        newErrors.push('Please provide a message')
        setIsMessageValid(false)
      }
      setErrors(newErrors)
    } finally {
      setIsLoading(false)
    }
  }

  const handleChange = () => {
    setIsNameValid(true)
    setIsEmailValid(true)
    setIsMessageValid(true)
    setErrors([])
  }

  return (
    <>
      <header className="contactMasthead" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + "/images/contact-bg.jpg"})` }}>
        <div className="container position-relative px-4 px-lg-5">
          <div className="row gx-4 gx-lg-5 justify-content-center">
            <div className="col-md-10 col-lg-8 col-xl-7">
              <div className="contactTitles">
                <h1 className="contactTitle">Contact Me</h1>
                <span className="contactSubheading">Have questions? I have answers.</span>
              </div>
            </div>
          </div>
        </div>
      </header>
      {!isSent && <div className="container pb-4">
        <div className="row">
          <div className="col-lg-8 mx-lg-auto">
            <p className="contactIntro">Want to get in touch? Fill out the form below to send me a message. I'll get back to you as soon as possible.</p>
          </div>
          <form className="col-lg-8 mx-lg-auto">
            <div className="row">
              <div className="form-group col-md-6">
                <label className="formLabel">Name</label>
                <input onChange={handleChange} ref={name} type="text" className={classNames({
                  'form-control': true,
                  'is-invalid': !isNameValid
                })} placeholder="Name" minLength={2} required />
              </div>
              <div className="form-group col-md-6">
                <label className="formLabel">Email</label>
                <input onChange={handleChange} ref={email} type="email" className={classNames({ 'form-control': true, 'is-invalid': !isEmailValid })} placeholder="Email" required aria-required="true" />
              </div>
              <div className="form-group col-md-12">
                <label className="formLabel">Your message</label>
                <textarea onChange={handleChange} ref={message} className={classNames({ 'form-control': true, 'is-invalid': !isMessageValid })} placeholder="Enter your message" rows={10} required aria-required="true"></textarea>
              </div>
              <div className="form-group col-md-12">
                <button type="submit" className="contactButton btn btn-block btn-primary py-2" onClick={sendMessage} disabled={isLoading}>Send It</button>
              </div>
            </div>
          </form>
        </div>
      </div>}
      {isSent && <div className="container">
        <div className="row">
          <div className="col-lg-8 mx-lg-auto" style={{ textAlign: 'center' }}>
            Thanks!
          </div>
        </div>
      </div>}
      {errors.length > 0 && <div className="container">
        <div className="col-lg-8 mx-lg-auto">
          {errors.map((e, i) =>
            <div key={i} className="alert alert-danger" role="alert">
              {e}
            </div>
          )}
        </div>
      </div>}
    </>
  )
}
